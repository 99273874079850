body,
html,
h1,
h3,
h4 {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white !important;
}

@media only screen and (max-width: 600px) {
  #mobileChartButtons {
    margin-top: 0px;
  }
}

h2 {
  color: #42E9AE;
  font-weight: 700;
  font-size: 24px;
}

html,
body {
  overflow-y: auto;
  overflow-x: hidden !important;
  height: 100%;
}

.makeStyles-paper-11,
.MuiTypography-body1,
.MuiPaper-root {
  color: white !important;
}

.MuiContainer-root {
  width: 80% !important;
  margin-left: 20% !important;
  margin-right: 0% !important;
  margin-top: -50% !important;
  overflow-x: hidden !important;
}


#unlockWalletButton {
  left: 60% !important;
}

@media only screen and (max-width: 600px) {
  .MuiContainer-root {
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 0% !important;
  } 
  #unlockWalletButton {
    left: 50% !important;
  }
}


#basecampRoot {
  margin-left: 0% !important;
  width: 100% !important;
  margin-top: 0% !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'Amarante', cursive !important; */
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: white !important;
}



.wallet-button {
  color: #11E2B7 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  background-color: black !important;
  color: #757ce8 !important;
}

.MuiToolbar-root{
  display: flow-root !important;
  width: 100% !important;
}

@media only screen and (max-width: 1100px)  {
  .MuiToolbar-root a {
    font-size: 10px !important;
    margin-left: 0px !important;
  }
}

Button:hover {
  color: white !important;
}

.card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #fff;
  margin: 20px 10px;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
/* Selectable */
.card .selectable {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 4px solid transparent;
}
.selectable-card {
  color: #04E09F!important;
  display: flex;
}
.card .selectable .check {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 20px;
  height: 20px;
}
.card .selectable .check:before {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  content: '';
  border: 20px solid;
  border-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.card .selectable .check .checkmark {
  display: block;
  font: 20px sans-serif;
  line-height: 20px;
  text-align: center;
  color: transparent;
}
.card .selectable.selected {
  border-color: #4ad;
}
.card .selectable.selected .check:before {
  border-color: #4ad #4ad rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
}
.card .selectable.selected .check .checkmark {
  color: #fff;
}
.content {
  padding: 15px;
}
.content .title, .content .description {
  margin: 0;
  padding: 4px;
}
.column {
  float: left;
  width: 50%;
}
.column > .title {
  text-align: center;
}
button.card {
  display: block;
  cursor: pointer;
  width: 180px;
  margin: 20px auto;
  text-align: center;
  padding: 16px;
  border-color: transparent;
  border-radius: 10px;
  background: #4ad !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
}
button.card:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
